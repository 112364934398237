import React from "react";
import {
  cellRenderer,
  currencyFormatter,
  generateOriginalValue,
  handleNumDisplay,
  filterParams,
} from "../../utils/ReportUtils";

const url = new URL(window.location.href);
const hotelId = url.searchParams.get("hotelId");

export class GenerateRoomRevCols {
  columns = [];
  constructor(type, hotelCurrency) {
    this.type = type;
    this.hotelCurrency = hotelCurrency;
  }

  generateColumns() {
    switch (this.type) {
      case "roomRevenueReport":
        this.columns.push([
          {
            field: "bookingid_display",
            headerName: "Booking Id",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            cellRendererFramework: (params) => cellRenderer(params, hotelId),
            dataType: "varchar",
            printWidth: "5%",
          },
          {
            field: "email",
            headerName: "Email",
            hide: true,
            dataType: "varchar",
            printWidth: "12%",
          },
          {
            field: "user_name",
            headerName: "Guest",
            dataType: "varchar",
            printWidth: "10%",
          },
          {
            field: "checkin",
            headerName: "Check-In",
            dataType: "varchar",
            printWidth: "10%",
          },
          {
            field: "checkout",
            headerName: "Check-Out",
            dataType: "varchar",
            printWidth: "10%",
          },
          {
            field: "room_ids",
            headerName: "Room no.(s)",
            dataType: "varchar",
            printWidth: "7%",
          },
          {
            field: "status",
            headerName: "Booking Status",
            dataType: "varchar",
          },
          {
            field: "source",
            headerName: "Source",
            hide: false,
            dataType: "varchar",
          },
          {
            field: "total_amount_with_services",
            headerName: `Total amt. (${this.hotelCurrency})`,
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "total_amount_with_services"),
            hide: false,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "7%",
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "room_revenue",
            headerName: `Room Revenue (${this.hotelCurrency})`,
            aggFunc: "sum",
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "7%",
            valueGetter: (params) =>
              generateOriginalValue(params, "room_revenue"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "room_taxes",
            headerName: `Room Taxes (${this.hotelCurrency})`,
            aggFunc: "sum",
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "7%",
            valueGetter: (params) =>
              generateOriginalValue(params, "room_taxes"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "booking_amount",
            headerName: `Booking amt. (${this.hotelCurrency})`,
            filter: "agNumberColumnFilter",
            dataType: "float",
            hide: true,
            printWidth: "7%",
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "booking_amount"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "services_amount",
            headerName: `Services amt. (${this.hotelCurrency})`,
            aggFunc: "sum",
            hide: true,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "7%",
            valueGetter: (params) =>
              generateOriginalValue(params, "services_amount"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "balance_amount",
            headerName: `Balance amt. (${this.hotelCurrency})`,
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "balance_amount"),
            // hide: true,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "7%",
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "custom_invoice_id",
            headerName: "Custom invoice ID",
            hide: true,
            dataType: "varchar",
            printWidth: "7%",
          },
          {
            field: "external_payment_card",
            headerName: `External/OTA Card Paid (${this.hotelCurrency})`,
            aggFunc: "sum",
            hide: true,
            filter: "agNumberColumnFilter",
            valueGetter: (params) =>
              generateOriginalValue(params, "external_payment_card"),
            dataType: "float",
            printWidth: "7%",
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "offline_payment",
            headerName: `Offline/Counter paid (${this.hotelCurrency})`,
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "offline_payment"),
            hide: true,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "7%",
          },
          {
            field: "payment_external",
            headerName: `External/OTA Cash Paid (${this.hotelCurrency})`,
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "payment_external"),
            hide: true,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "7%",
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "payment_gateway_amount",
            headerName: `Online/PG/Terminal Paid (${this.hotelCurrency})`,
            aggFunc: "sum",
            hide: true,
            valueGetter: (params) =>
              generateOriginalValue(params, "payment_gateway_amount"),
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "7%",
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "fee_amount",
            headerName: `Fee amount (${this.hotelCurrency})`,
            aggFunc: "sum",
            // hide: true,
            valueGetter: (params) =>
              generateOriginalValue(params, "fee_amount"),
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "7%",
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "refund",
            headerName: `Total refunds (${this.hotelCurrency})`,
            aggFunc: "sum",
            valueGetter: (params) => generateOriginalValue(params, "refund"),
            hide: true,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "7%",
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },

          {
            field: "Partial_exempt",
            headerName: `Partial Tax Exempt`,
            hide: true,
            dataType: "varchar",
            printWidth: "5%",
          },
          {
            field: "Total_exempt",
            headerName: `Total Tax Exempt`,
            hide: true,
            dataType: "varchar",
            printWidth: "5%",
          },
          {
            field: "taxable_revenue",
            headerName: `Taxable Revenue (${this.hotelCurrency})`,
            aggFunc: "sum",
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "7%",
            valueGetter: (params) =>
              generateOriginalValue(params, "taxable_revenue"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "tax_exempt_revenue",
            headerName: `Tax Exempt Revenue (${this.hotelCurrency})`,
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "tax_exempt_revenue"),
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "7%",
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "excess_exempt",
            headerName: `Excess exempt `,
            filter: "agNumberColumnFilter",
            dataType: "varchar",
            printWidth: "7%",
            hide: true,
          },
        ]);

        break;

      case "roomRevenueReportSource":
        this.columns.push([
          {
            field: "source",
            headerName: "Source",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            dataType: "varchar",
            printWidth: "12%",
          },
          {
            field: "rooms",
            headerName: "Rooms sold",
            filter: "agNumberColumnFilter",
            dataType: "integer",
            printWidth: "10%",
            aggFunc: "sum",
            valueGetter: (params) => generateOriginalValue(params, "rooms"),
            valueFormatter: (params) => handleNumDisplay(params, "integer"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "integer"),
          },
          {
            field: "room_revenue",
            headerName: `Room revenue ( ${this.hotelCurrency} )`,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "15%",
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "room_revenue"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "room_taxes",
            headerName: `Room taxes ( ${this.hotelCurrency} )`,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "15%",
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "room_taxes"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "booking_amount",
            headerName: `Total booking amount ( ${this.hotelCurrency} )`,
            hide: true,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "12%",
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "booking_amount"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "services_amount",
            headerName: `Total services amount ( ${this.hotelCurrency} )`,
            filter: "agNumberColumnFilter",
            hide: false,
            filterParams: filterParams,
            dataType: "float",
            printWidth: "12%",
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "services_amount"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "total_amount_with_services",
            headerName: `Total final amount ( ${this.hotelCurrency} )`,
            hide: true,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "12%",
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "total_amount_with_services"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "balance_amount",
            headerName: `Final balance amount ( ${this.hotelCurrency} )`,
            hide: false,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "12%",
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "balance_amount"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
        ]);
        break;
      case "roomRevenueReportRateplan":
        this.columns.push([
          {
            field: "rate_plan",
            headerName: "Rate plan",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            dataType: "varchar",
            printWidth: "12%",
          },
          {
            field: "rooms",
            headerName: "Rooms sold",
            filter: "agNumberColumnFilter",
            dataType: "integer",
            printWidth: "10%",
            aggFunc: "sum",
            valueGetter: (params) => generateOriginalValue(params, "rooms"),
            valueFormatter: (params) => handleNumDisplay(params, "integer"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "integer"),
          },
          {
            field: "room_revenue",
            headerName: `Room revenue ( ${this.hotelCurrency} )`,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "15%",
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "room_revenue"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "room_taxes",
            headerName: `Room taxes ( ${this.hotelCurrency} )`,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "15%",
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "room_taxes"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "booking_amount",
            headerName: `Total booking amount ( ${this.hotelCurrency} )`,
            hide: false,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "12%",
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "booking_amount"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "services_amount",
            headerName: `Total services amount ( ${this.hotelCurrency} )`,
            filter: "agNumberColumnFilter",
            hide: false,
            filterParams: filterParams,
            dataType: "float",
            printWidth: "12%",
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "services_amount"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "total_amount_with_services",
            headerName: `Total final amount ( ${this.hotelCurrency} )`,
            hide: false,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "12%",
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "total_amount_with_services"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
          {
            field: "balance_amount",
            headerName: `Final balance amount ( ${this.hotelCurrency} )`,
            hide: false,
            filter: "agNumberColumnFilter",
            dataType: "float",
            printWidth: "12%",
            aggFunc: "sum",
            valueGetter: (params) =>
              generateOriginalValue(params, "balance_amount"),
            valueFormatter: (params) => handleNumDisplay(params, "float"),
            pinnedRowValueFormatter: (params) =>
              handleNumDisplay(params, "float"),
          },
        ]);
        break;
    }

    console.log("rr report cols : ", this.columns);
    return this.columns;
  }
}
