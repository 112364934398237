import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import { connect, shallowEqual, useSelector } from "react-redux";
import LockResetIcon from "@mui/icons-material/LockReset";
import {
  Grid,
  Button,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  stepButtonClasses,
  CircularProgress,
  ListItemText,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  IconButton,
  Divider,
  Stack,
  ClickAwayListener,
  Box,
} from "@mui/material";
// import UserModal from "./UserModal";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import { toast } from "material-react-toastify";
import MockLoaderTable from "../Folios/ViewManagePayments/MockLoaderTable";
import moment from "moment";
import { DateRange } from "react-date-range";
import { SecondaryButton } from "../../Utils/ButtonHelper";
import RunNightAuditModal from "./RunNightAuditModal";
import FastForwardIcon from "@mui/icons-material/FastForward";
import NightAuditReportModal from "./NightAuditReportModal";
import SummarizeIcon from "@mui/icons-material/Summarize";
import NotesModal from "./NotesModal";
import { useTranslation } from "react-i18next";

// import EditUserAccessModal from "./EditUserAccessModal";

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 10,
  zIndex: 100,
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

function NightAuditHistory({}) {
  const { t } = useTranslation();

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const [raised, setRaised] = useState({});
  const [historyList, sethistoryList] = useState([]);
  const [dates, setdates] = useState(new Date());

  const [loader, setloader] = useState(false);
  const [openNightAudit, setopenNightAudit] = useState(false);
  const [openNightAuditReport, setopenNightAuditReport] = useState(false);
  const [nightAduitData, setnightAduitData] = useState();
  const [mode, setmode] = useState("");
  const [progress, setProgress] = useState(0);
  const [nigthAuditDate, setnigthAuditDate] = useState(new Date());
  const [selectedState, setselectedState] = useState("");
  const [selectedStatus, setselectedStatus] = useState("CHECK_IN");
  const [isNightAuditRunned, setisNightAuditRunned] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [notesModal, setnotesModal] = useState(false);
  const [notesData, setnotesData] = useState("");

  const openNotesModal = (data) => {
    setnotesData(data);
    setnotesModal(true);
  };

  const closeNotesModal = () => {
    setnotesModal(false);
  };

  const handleOpenNightAuditReportModal = (num) => {
    setnigthAuditDate(
      num.includes("AM")
        ? moment(num, "DD MMM YYYY hh:mm A").subtract(2, "days")
        : moment(num, "DD MMM YYYY hh:mm A").subtract(1, "days")
    );
    setopenNightAuditReport(true);
  };
  const handleCloseNightAuditReportModal = () => setopenNightAuditReport(false);

  const handleOpenNightAuditModal = (numDate, isToday, num) => {
    if (isToday) {
      // createTracker();
      setdates(numDate);
      setopenNightAudit(true);
      setmode("MANUAL");
    } else {
      setdates(numDate);
      setnightAduitData(num);
      setopenNightAudit(true);
      setmode(num.mode);
    }
  };
  const handleCloseNightAuditModal = () => {
    setopenNightAudit(false);
    setProgress(0);
    setCurrentStep(1);
    setselectedStatus("CHECK_OUT");
    setselectedState("");
    setisNightAuditRunned(false);
    setnightAduitData();
  };

  // Getting the start date as well as the end date to load the data
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //open and close date picker
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  const toggleRaised = (num) => {
    raised[num] = !raised[num];
    setRaised({ ...raised });
  };

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    setloader(true);
    handleSpringDataRequest(
      `core/api/v1/nightAuditTracker/get-range-night-audit-tracker?endRange=${moment(
        dateRange[0].endDate
      ).format("DD-MM-YYYY")}&startRange=${moment(dateRange[0].startDate)
        .add(1, "days")
        .format("DD-MM-YYYY")}`
    )
      .then((res) => {
        sethistoryList(res);
        setloader(false);
      })
      .catch((err) => {
        toast.error(`${t("Something went wrong")}`);
        setloader(false);
      });
  };

  const createTracker = () => {
    const payload = {
      auditDate: moment(dates).format("DD-MM-YYYY"),
      hotelId: hotel.hotelId,
      mode: "MANUAL",
      nightAuditStatus: "PENDING",
    };
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/nightAuditTracker/create`,
      payload
    )
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
  };

  const [status, setstatus] = useState("DISABLE");
  const [includeAM, setincludeAM] = useState(false);

  const getData = () => {
    handleSpringDataRequest(
      `core/api/v1/nightAuditConfig/get-night-audit-configuration`
    )
      .then((res) => {
        if (Object.entries(res).length > 0) {
          setstatus(res.status);
          setincludeAM(convertTo12HourFormat(res.time).includes("AM"));
        }
      })
      .catch((err) => toast.error(`${t("Error in fetching details")}`));
  };
  useEffect(() => {
    getData();
  }, []);

  function convertTo12HourFormat(time24) {
    const [hour, minute] = time24.split(":");
    const parsedHour = parseInt(hour);

    if (parsedHour === 0) {
      return `12:${minute} AM`;
    } else if (parsedHour < 12) {
      return `${parsedHour}:${minute} AM`;
    } else if (parsedHour === 12) {
      return `12:${minute} PM`;
    } else {
      return `${parsedHour - 12}:${minute} PM`;
    }
  }

  return loader === false ? (
    <div className="w-100">
      <Grid container style={{ width: "100%" }}>
        <div className="d-flex flex-column w-100">
          <h2>{t("Night audit history")}</h2>
          <p>
            {t(
              "The Night Audit display the report and status (Pending or Success) of the night audit for a specific date. If a night audit is Pending for a particular date, you can run it by clicking on the arrow given next to Pending status. Click on the run night audit button below to run the night audit for the given date."
            )}
          </p>
          <Divider />
          <div className="d-flex justify-content-between w-100 mt-3">
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <button
                  onClick={handleClick}
                  className="border rounded p-2"
                  style={{
                    backgroundColor: "transparent",
                    height: "37.5px",
                  }}
                >
                  {moment(dateRange[0].startDate).format("DD MMM YYYY")}{" "}
                  {" to "}
                  {moment(dateRange[0].endDate).format("DD MMM YYYY")}
                </button>
                {open ? (
                  <Box sx={styles}>
                    <Stack spacing={3}>
                      <DateRange
                        editableDateInputs={true}
                        onChange={(item) => setDateRange([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                        maxDate={new Date()}
                      />
                      <Stack
                        className="position-relative w-100 d-flex justify-content-between"
                        direction="row"
                      >
                        <SecondaryButton
                          text={t("Cancel")}
                          onClick={() => setOpen(false)}
                        />
                        <Button
                          variant="custom-button"
                          onClick={() => {
                            getAllData();
                            handleClickAway();
                          }}
                        >
                          {t("Apply")}
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                ) : null}
              </Box>
            </ClickAwayListener>
            <Button
              disabled={status === "DISABLE"}
              onClick={() =>
                handleOpenNightAuditModal(
                  includeAM
                    ? new Date()
                    : new Date().setDate(new Date().getDate() - 1),
                  true
                )
              }
              variant="custom-button"
              className="ml-4"
            >
              {t("Run night audit")}
            </Button>
          </div>
        </div>

        <Grid item container sx={{ marginBottom: 3 }} xs={12}>
          <Grid item container>
            <div className="d-flex justify-content-between align-item-center w-100">
              <div className="w-100 d-flex">
                <div className="d-flex align-item-center">
                  <div
                    style={{
                      marginTop: "18px",
                      heigth: "35px !important",
                    }}
                  >
                    {/* <Button
                      onClick={() => openUserRoleModal()}
                      variant="custom-button"
                    >
                      Add user
                    </Button> */}
                  </div>

                  {/* <TextField
                    onChange={(e) => setsearchTerm(e.target.value)}
                    placeholder="Search by email"
                    label="Search by email"
                    // className="w-75"
                    notched={false}
                    type="search"
                    size="small"
                    style={{
                      borderRadius: "5px",
                      width: "55%",
                      marginBottom: "2rem",
                      marginLeft: "2rem",
                      marginTop: "17px",
                    }}
                  /> */}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        {historyList.map((num, index) => {
          return (
            <Grid key={index} item xs={12}>
              <Card
                onMouseOver={() => {
                  toggleRaised(num.audit_date + num.hotelId);
                }}
                onMouseOut={() => {
                  toggleRaised(num.audit_date + num.hotelId);
                }}
                raised={raised[num.audit_date + num.hotelId]}
                sx={{ marginBottom: 2 }}
              >
                <CardHeader
                  title={moment(num.audit_date, "DD MMM YYYY hh:mm A")
                    .subtract(1, "days")
                    .format("DD MMM YYYY")}
                  action={
                    <Grid item spacing={2} container>
                      {num.night_audit_status === "SUCCESS" && (
                        <Grid item>
                          <div
                            className="badge badge-success ml-2"
                            style={{
                              fontSize: "11px",
                              marginTop: "8px",
                              fontWeight: "900",
                            }}
                          >
                            {t("SUCCESS")}
                          </div>
                        </Grid>
                      )}
                      {num.night_audit_status === "PENDING" && (
                        <Grid item>
                          <div
                            className="badge badge-danger ml-2"
                            style={{
                              fontSize: "11px",
                              marginTop: "8px",
                              fontWeight: "900",
                            }}
                          >
                            {t("PENDING")}
                          </div>
                        </Grid>
                      )}

                      {num.night_audit_status !== "SUCCESS" && (
                        <Grid item>
                          <Tooltip title="Run night audit">
                            <IconButton color="primary">
                              <FastForwardIcon
                                onClick={() =>
                                  handleOpenNightAuditModal(
                                    num.audit_date,
                                    false,
                                    num
                                  )
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}

                      {num.night_audit_status === "SUCCESS" && (
                        <Grid item>
                          <Tooltip title="Report">
                            <IconButton color="primary">
                              <SummarizeIcon
                                onClick={() =>
                                  handleOpenNightAuditReportModal(
                                    num.audit_date
                                  )
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  }
                >
                  <Divider />
                </CardHeader>
                <Divider />
                <CardContent>
                  <Grid item container spacing={2}>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                      <ListItemText
                        primary={t("Status")}
                        secondary={`${num.night_audit_status}`}
                      ></ListItemText>
                    </Grid>

                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                      <ListItemText
                        primary={t("Mode")}
                        secondary={`${num.mode}`}
                      ></ListItemText>
                    </Grid>

                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                      <ListItemText
                        primary={t("Night audit run time")}
                        secondary={`${num.audit_date}`}
                      ></ListItemText>
                    </Grid>

                    {num.notes.length > 0 && (
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItemText primary={t("Notes")}></ListItemText>
                        <p
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => openNotesModal(num)}
                        >
                          {" "}
                          {`${num.notes.substring(0, 65)}...`}
                        </p>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {openNightAudit && (
        <RunNightAuditModal
          show={openNightAudit}
          hide={handleCloseNightAuditModal}
          dates={dates}
          nightAduitData={nightAduitData}
          mode={mode}
          progress={progress}
          setProgress={setProgress}
          selectedState={selectedState}
          setselectedState={setselectedState}
          selectedStatus={selectedStatus}
          setselectedStatus={setselectedStatus}
          isNightAuditRunned={isNightAuditRunned}
          setisNightAuditRunned={setisNightAuditRunned}
          getAllNightAuditData={getAllData}
          setdates={setdates}
          createTracker={createTracker}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          includeAM={includeAM}
        ></RunNightAuditModal>
      )}
      {openNightAuditReport && (
        <NightAuditReportModal
          show={openNightAuditReport}
          hide={handleCloseNightAuditReportModal}
          nigthAuditDate={nigthAuditDate}
        ></NightAuditReportModal>
      )}
      {notesModal && (
        <NotesModal
          show={notesModal}
          hide={closeNotesModal}
          notesData={notesData}
        ></NotesModal>
      )}
    </div>
  ) : (
    <MockLoaderTable />
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(NightAuditHistory);
