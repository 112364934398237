import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CountryAndCity from "./CountryAndCity.json";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../api";
import { toast } from "material-react-toastify";

function SalesData() {
  const [country, setCountry] = useState("");
  const [allCountries, setAllCountries] = useState([]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [allCities, setAllCities] = useState([]);
  const [allStates, setAllStates] = useState([]);

  useEffect(() => {
    if (country) {
      const resp =
        CountryAndCity.find((item) => item.name === country)?.states || [];
      setAllStates(resp);
      setState(""); // Reset state when country changes
      setCity(""); // Reset city when country changes
    } else {
      setAllStates([]);
      setState("");
      setCity("");
    }
  }, [country]);

  useEffect(() => {
    if (state) {
      const resp =
        allStates
          .find((item) => item.name === state)
          ?.cities.map((res) => res.name) || [];
      setAllCities(resp);
      setCity(""); // Reset city when state changes
    } else {
      setAllCities([]);
      setCity("");
    }
  }, [state]);

  const [loader, setloader] = useState(false);
  const Save = () => {
    setloader(true);
    handleSpringDataRequest(
      `web-api/get-all-sales-data-details?listingId=26046&city=${city}&country=${country}`
    )
      .then((resp) => {
        toast.success(
          "Successfull dont click again you will get hotels data in 20 min"
        );
        setloader(false);
      })
      .catch((err) => {
        setloader(false);
        toast.error("Please try again after 2 hours");
      });
  };

  return (
    <div className="d-flex flex-column w-100">
      <Autocomplete
        options={CountryAndCity.map((item) => item.name)}
        value={country}
        onChange={(event, newValue) => setCountry(newValue)}
        renderInput={(params) => <TextField {...params} label="Country" />}
        style={{ marginTop: "1rem" }}
      />
      <Autocomplete
        options={allStates.map((item) => item.name)}
        value={state}
        onChange={(event, newValue) => setState(newValue)}
        renderInput={(params) => <TextField {...params} label="State" />}
        style={{ marginTop: "1rem" }}
      />
      <Autocomplete
        options={allCities}
        value={city}
        onChange={(event, newValue) => setCity(newValue)}
        renderInput={(params) => <TextField {...params} label="City" />}
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
      />
      <Button
        variant="custom-button"
        onClick={Save}
        type="submit"
        disabled={loader}
      >
        {loader ? (
          <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
        ) : (
          `Save`
        )}
      </Button>
    </div>
  );
}

export default SalesData;
